import { UPDATE_CLIENTINFO } from "../constants/clientinfo";
import { isEmpty } from "validator";

let initalState = {
    ip_info: {
        "ipString": null,
        "ipNumeric": null,
        "ipType": null,
        "isBehindProxy": null,
        "device": null,
        "os": null,
        "userAgent": null,
        "family": null,
        "versionMajor": null,
        "versionMinor": null,
        "versionPatch": null,
        "isSpider": null,
        "isMobile": null,
        "userAgentDisplay": null,
        "userAgentRaw": null,
        "userLanguages": null
    }
};

const clientInfoReducer = (state = initalState, action) => {
  switch (action.type) {
    case UPDATE_CLIENTINFO: 
      {
        const updateState = { ...state };
        updateState.ip_info = action.payload;
        return updateState;
      }
    default:
      return state;
  }
};
export default clientInfoReducer;