import axios from "axios";
import { UPDATE_CLIENTINFO } from "../constants/clientinfo";

export const updateClientInfo = () => {
    return dispatch => {
        axios.get("https://api.bigdatacloud.net/data/client-info") 
            .then(res => {
                dispatch({
                    type: UPDATE_CLIENTINFO,
                    payload: res.data
                });
        })
    }
};
