import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Route, BrowserRouter, HashRouter, Switch, Redirect } from "react-router-dom";

import LoginGuard from "../components/guard";
import LoginGuardApp from "../components/guard/loginGuardApp";
import { actLoginUser, logout } from "../store/actions/user";
import { updateClientInfo } from "../store/actions/clientinfo";
import setAuthToken from '../utils/setAuthToken';
import jwtDecode from 'jwt-decode'
import { Store } from "tough-cookie";
import { declareTypeAlias } from "@babel/types";
import { ROUTE } from '../store/constants/config'
const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('../containers/DefaultLayout'));
const DefaultLayoutApp = React.lazy(() => import('../containers/DefaultLayout/DefaultLayoutApp'))

//Login
const Login = React.lazy(() => import('../views/Login'));
const LoginApp = React.lazy(() => import('../views/Login/LoginApp'));
const Register = React.lazy(() => import('../views/Register'));
const FaceRecpush = React.lazy(() => import('../views/FaceRecpush/FaceRegisterRecpush'));
const FaceCheckIn = React.lazy(() => import('../views/FaceRecpush/FaceCheckIn'));
const FaceCheckTest = React.lazy(() => import('../views/FaceRecpush/FaceDetection'));
const FaceCheckOut = React.lazy(() => import('../views/FaceRecpush/FaceCheckOut'));
const FaceVisitor = React.lazy(() => import('../views/FaceRecpush/VisitorAppointment'));
const Home = React.lazy(() => import('../views/FaceRecpush/Home'));
const HomePage = React.lazy(() => import('../views/FaceRecpush/HomePage'));
const His = React.lazy(() => import('../views/FaceRecpush/HisTimesheet'));
class Layout extends Component {

  render() {
    if (localStorage.jwtToken) {
      setAuthToken(localStorage.jwtToken)
    }
    return (
      <BrowserRouter >
        <React.Suspense fallback={loading()}>
          <Switch>
            {/* <Route
              exact
              path="/Register"
              name="Register Page"
              render={props => <Register {...props} />}
            /> */}

            <Route
              exact
              path="/Login"
              name="Login Page"
              render={props => <Login {...props} />}
            />

            <Route
              exact
              path="/LoginApp"
              name="Login Web App Page"
              render={props => <LoginApp {...props} />}
            />

            <Route
              exact
              path="/FaceRecpush"
              name="Nhận diện khuôn mặt"
              render={props => <FaceRecpush {...props} />}
            />

            <Route
              exact
              path="/FaceCheckIn"
              name="Chấm công vào"
              render={props => <FaceCheckIn {...props} />}
            />

            <Route
              exact
              path="/facetest"
              name="Chấm công vào"
              render={props => <FaceCheckTest {...props} />}
            />

            {/* <Route
              exact
              path="/FaceCheckOut"
              name="Chấm công ra"
              render={props => <FaceCheckOut {...props} />}
            /> */}

            {/* <Route
              exact
              path="/VisitorAppointment"
              name="Đăng ký lịch hẹn"
              render={props => <FaceVisitor {...props} />}
            />

            <Route
              exact
              path="/VisitorAppointment/:id"
              name="Đăng ký lịch hẹn"
              render={props => <FaceVisitor {...props} />}
            />             */}

            <Route
              exact
              path="/Home"
              name="Trang chủ"
              render={props => <Home {...props} />}
            />

            {/* <Route
              exact
              path="/HomePage"
              name="Trang chủ"
              render={props => <HomePage {...props} />}
            /> */}

            <Route
              exact
              path="/forgotpassword"
              name="Quên mật khẩu Webapp"
              render={props => <His {...props} />}
            />

            <LoginGuard path={"/auth"} component={DefaultLayout} />

            <LoginGuardApp path={"/app"} component={DefaultLayoutApp} />

            <Route
              exact
              path="/"
              name="Trang chủ"
              render={props => <Home {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
  componentDidMount() {
    const user = localStorage.getItem("userLogin");
    if (user) {
      this.props.onLoadUserProfile(JSON.parse(user));
    }

    this.props.resetClientInfo()
    const intervalID = setInterval(() => {
        this.props.resetClientInfo()
    }, 900000)
  }

  componentWillUnmount() {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLoadUserProfile: user => {
      const { token } = user;
      setAuthToken(token);
      const decoded = jwtDecode(token);
      dispatch(actLoginUser(decoded));
    },
    onCheckToken: () => {
      dispatch(logout())
    },
    resetClientInfo: () => {
      dispatch(updateClientInfo())
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(Layout);