import React, { Component } from 'react';
import './App.scss';
import Layout from './layouts';

class App extends Component {

  render() {
    return (
      <Layout></Layout>
    );
  }
}

export default App;
